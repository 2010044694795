import React from "react";
import { DynamicGradientSvgText } from "../components/DynamicGradientSvgText";
import { HobLink as Link, IHobLinkProps } from "../components/HobLink";

export const MemoizedLink = React.memo(
  ({
    label,
    href = "",
    height,
    offset,
    color
  }: IHobLinkProps & {
    label: string;
    height: number;
    offset: number;
    color?: string;
  }) => (
    <Link
      className={`nav__item nav__item--${label}`}
      color={color}
      href={href}
      unsetTypography={true}
      style={{fontSize: height}}
    >
      {label}
    </Link>
  ),
  (a, b) => a.offset === b.offset
);
